var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Latest order list ")]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img",attrs:{"src":item.avatar.url,"alt":""}}),_c('span',{staticClass:"c-avatar-status",class:("bg-" + (item.avatar.status || 'secondary'))})])])}},{key:"user",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.user.name))]),_c('div',{staticClass:"small text-muted"},[_c('span',[(item.user.new)?[_vm._v("New")]:[_vm._v("Recurring")]],2),_vm._v(" | Registered: "+_vm._s(item.user.registered)+" ")])])}},{key:"country",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('CIcon',{attrs:{"name":item.country.flag,"height":"25"}})],1)}},{key:"order",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"},[_c('strong',[_vm._v(_vm._s(item.order.value)+"%")])]),_c('div',{staticClass:"float-right"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.order.period))])])]),_c('CProgress',{staticClass:"progress-xs",attrs:{"color":_vm.color(item.order.value)},model:{value:(item.order.value),callback:function ($$v) {_vm.$set(item.order, "value", $$v)},expression:"item.order.value"}})],1)}},{key:"payment",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('CIcon',{attrs:{"name":item.payment.icon,"height":"25"}})],1)}},{key:"activity",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"small text-muted"},[_vm._v("Last login")]),_c('strong',[_vm._v(_vm._s(item.activity))])])}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }